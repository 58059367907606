import React from 'react';
import '../components/timmi.css'
import { StaticQuery, graphql } from "gatsby"

export default function Lab(){
    return(
        <section className="timmiPage" id="lab">
               <h1 className="pageHeading">lab</h1>
        </section>
    )
}
